import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
if (window.location.href.includes("en/") || window.location.href.includes("/en")) import('./index_en.css');
else import('./index.css');

//ReactDOM.hydrate(
//  <BrowserRouter>
//   <React.StrictMode>
//      <App />
//    </React.StrictMode>
//  </BrowserRouter>,
//  document.getElementById('root')
//);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

//serviceWorkerRegistration.register();